import 'bootstrap/dist/css/bootstrap.min.css';
import MapForm from '../MapForm/MapForm';
import {Form, Button, Container, Row, Col, Dropdown} from 'react-bootstrap'; 
import {useState} from 'react'; 
import DataManager from '../../utility/DataManager';
import BackEndConnector from '../../utility/BackEndConnector';
import IncDataAlert from '../IncDataAlert/IncDataAlert';



// API Key: AIzaSyBfze98K_dTcAVK_mbukQgexRfmtQ4wRNE

// More secure method of storing API Key on the google react tutorial if needeed



export default function InfoForm({hidden, setVisibility}) {


    let screenHeight = window.screen.height * 0.87; 

    let [currLocation, setFormLocation] = useState(null); 

    let[{birthday, birthtime}, setFormData] = useState({birthday: null, birthtime: "12:00 PM"})

    let [gender, setGender] = useState(null); 

    let [popupVisible, setPopup] = useState(false); 

    let [errorText, setErrorText] = useState(""); 




    let mapScript = "Click where you were born on the map!"; 
    
    if(currLocation != null) {
        mapScript = "Lat: " + Math.round(currLocation[0] * 100) / 100
        + ", Lng: " + Math.round(currLocation[1] * 100) / 100; 
    }

    return (
        <>
        {
            // Include back-button here
        }
        <Container hidden={hidden} className="align-items-center" 
            style={{height: screenHeight}}
        >

            <Row style={{height: screenHeight * 0.1}}></Row>

            <Row>
                <Col align="center">
                    <h1>
                        Personal Information Form
                    </h1>
                </Col>

            </Row>
            <Row style={{height: screenHeight * 0.08}}></Row>
            <Row className="justify-content-center">
                <Col xs lg = {6} style={{border: "2px solid black", padding:"30px", background:"#9fdf9f"}}>
                    <Form>

                        <Form.Group className="mb-3" controlId="formBirthdate">
                            <Form.Label>Birthdate (MM/DD/YYYY)</Form.Label>
                            <Form.Control type="text" placeholder="Birthdate" 
                                onChange={(event) => {setFormData({birthday: event.target.value, birthtime: birthtime})}} />
                        </Form.Group>

                        <Container>
                            <Row>

                                <Col>

                                    <Form.Group className="mb-3" controlId="formBirthtime">
                                        <Form.Label>Birth Time (HH:MM AM/PM)</Form.Label>
                                        <Form.Control type="text" placeholder="12:00 PM"
                                        onChange={(event) => {setFormData({birthday: birthday, birthtime: event.target.value})}} />
                                    </Form.Group>

                                </Col>

                            <Col>
                                <Form.Group className="mb-3" controlId="formGender">
                                <Form.Label>Gender:</Form.Label>  
                                <Form.Control type="text" placeholder="F/M" onChange={(event) => {setGender(event.target.value)}}/>
                                </Form.Group>
                            </Col>
        
                            </Row>


                        </Container>

                        <Container>


                        </Container>

                    </Form>

                    <p>Location: </p>

                    <p style={{background:"white", border:"1px solid black", padding: "5px"}}>{mapScript}</p>

                    <Button variant="primary" type="submit" onClick={() => handleSubmit()}>
                        Submit
                    </Button>

                </Col>

                <Col xs lg = {6}>
                    <MapForm setFormLocation={setFormLocation}></MapForm>
                </Col>
            </Row>
        </Container>

        <IncDataAlert visible={popupVisible} showPopup={setPopup} errorText={errorText}></IncDataAlert>
        </>
    ); 

    function handleSubmit() {

        let errorText = ""; 

        try {
            let placeholder = new Date(birthday + " " + birthtime);

            if(birthtime.length != 8) throw new Error("Time entered incorrectly")

            if(placeholder == "Invalid Date") throw new Error("Invalid Date entered"); 
            if(birthday == null) throw new Error("Birthday not entered"); 
        }
        catch(e) {
            errorText += "Either the date or the time was entered in an incorrect format. \n"
        }

        if(currLocation == null) {
            errorText += "The location of birth was not included. Click on the map to set your birth location. \n"; 
        }

        if(gender != "F" && gender != "M") errorText += "Your gender was not entered correctly. "



        if(errorText != "") {
            setErrorText(errorText); 
            setPopup(true); 
            return; 
        }

        let latMin = Math.round((currLocation[0] - Math.floor(currLocation[0])) * 60); 

        let lngMin = Math.round((currLocation[1] - Math.floor(currLocation[1])) * 60); 

        DataManager.userEntered = true; 
        BackEndConnector.setUserData(birthday, currLocation[0], latMin, currLocation[1], lngMin, birthtime, gender); 

        setVisibility(3); 
    }

}