import {Container, Navbar, Nav, Col, Button} from 'react-bootstrap'; 

import {AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'; 

import {useState} from 'react'; 

import DataManager from '../../utility/DataManager';

export default function SideNav({visibility, sideNavToggle, setVisibility, showing}) {


    let navLabels = DataManager.sideNavLabels["" + visibility]; 

    // Implement side navbar

    // Refactor this so its not specifically for Table,
    // just general side nav bar. Get specific navbar info from NavBarConfiguration

    if(showing) {
        return (
            <Col xs lg = {2} style={{background:"#0F5E8E", height:"120%", position:"absolute", borderRight:"15px solid #0F5E8E",
                borderTop:"15px solid #0F5E8E", marginLeft:0}}>

                <Button style={{background:"#0F5E8E"}} className="float-end" onClick={() => setVisibility(false)}>
                     <AiOutlineMenuFold size="30"></AiOutlineMenuFold>
                </Button>

                <Navbar data-bs-theme="dark">

                    <Nav defaultActiveKey="/home" variant="tabs" style={{fontSize:20, width:"100%"}} className="flex-column">


                        <Nav.Link hidden={navLabels[0] === undefined} onClick={() => sideNavToggle(0)}
                            eventKey="link-0" style={{color: "#FFFFFF"}}> {navLabels[0]} </Nav.Link>
                         <Nav.Link hidden={navLabels[1] === undefined} onClick={() => sideNavToggle(1)}
                            eventKey="link-1" style={{color: "#FFFFFF"}}> {navLabels[1]} </Nav.Link>
                         <Nav.Link hidden={navLabels[2] === undefined} onClick={() => sideNavToggle(2)}
                            eventKey="link-2" style={{color: "#FFFFFF"}}>{navLabels[2]} </Nav.Link>
                        <Nav.Link hidden={navLabels[3] === undefined} onClick={() => sideNavToggle(3)}
                            eventKey="link-3" style={{color: "#FFFFFF"}}> {navLabels[3]} </Nav.Link>
                         <Nav.Link hidden={navLabels[4] === undefined} onClick={() => sideNavToggle(4)}
                            eventKey="link-4" style={{color: "#FFFFFF"}}> {navLabels[4]} </Nav.Link>
                        <Nav.Link hidden={navLabels[5] === undefined} onClick={() => sideNavToggle(5)}
                            eventKey="link-5" style={{color: "#FFFFFF"}}> {navLabels[5]} </Nav.Link>
                        <Nav.Link hidden={navLabels[6] === undefined} onClick={() => sideNavToggle(6)}
                            eventKey="link-6" style={{color: "#FFFFFF"}}> {navLabels[6]} </Nav.Link>
                        <Nav.Link hidden={navLabels[7] === undefined} onClick={() => sideNavToggle(7)}
                            eventKey="link-7" style={{color: "#FFFFFF"}}> {navLabels[7]} </Nav.Link>
                        <Nav.Link hidden={navLabels[8] === undefined} onClick={() => sideNavToggle(8)}
                            eventKey="link-8" style={{color: "#FFFFFF"}}> {navLabels[8]} </Nav.Link>
                         <Nav.Link hidden={navLabels[9] === undefined} onClick={() => sideNavToggle(9)}
                            eventKey="link-9" style={{color: "#FFFFFF"}}> {navLabels[9]} </Nav.Link>
                        <Nav.Link hidden={navLabels[10] === undefined} onClick={() => sideNavToggle(10)}
                            eventKey="link-10" style={{color: "#FFFFFF"}}> {navLabels[10]} </Nav.Link>
                        <Nav.Link hidden={navLabels[11] === undefined} onClick={() => sideNavToggle(11)}
                            eventKey="link-11" style={{color: "#FFFFFF"}}> {navLabels[11]} </Nav.Link>
                        <Nav.Link hidden={navLabels[12] === undefined} onClick={() => sideNavToggle(12)}
                            eventKey="link-12" style={{color: "#FFFFFF"}}> {navLabels[12]} </Nav.Link>
                        <Nav.Link hidden={navLabels[13] === undefined} onClick={() => sideNavToggle(13)}
                            eventKey="link-13" style={{color: "#FFFFFF"}}> {navLabels[13]} </Nav.Link>
                         <Nav.Link hidden={navLabels[14] === undefined} onClick={() => sideNavToggle(14)}
                            eventKey="link-14" style={{color: "#FFFFFF"}}> {navLabels[14]} </Nav.Link>
                        <Nav.Link hidden={navLabels[15] === undefined} onClick={() => sideNavToggle(15)}
                            eventKey="link-15" style={{color: "#FFFFFF"}}> {navLabels[15]} </Nav.Link>
                        <Nav.Link hidden={navLabels[16] === undefined} onClick={() => sideNavToggle(16)}
                            eventKey="link-16" style={{color: "#FFFFFF"}}> {navLabels[16]} </Nav.Link>
                        <Nav.Link hidden={navLabels[17] === undefined} onClick={() => sideNavToggle(17)}
                            eventKey="link-17" style={{color: "#FFFFFF"}}> {navLabels[17]} </Nav.Link>
                    </Nav>

                </Navbar>
            </Col>
        ); 
    }

    else {

        return (
            /*<Col xs lg = {"auto"} style={{background:"#0F5E8E", height:"100%", position:"absolute", borderRight:"15px solid  #0F5E8E",
                borderTop:"15px solid #0F5E8E", marginLeft:0}}>
            */
                <Container xs lg = {2} className='align-items-left' fluid>

                    <Button style={{background:"#0F5E8E", align: "left", marginTop: 10}} onClick={() => setVisibility(true)}>
                        <AiOutlineMenuUnfold size="30"></AiOutlineMenuUnfold>
                    </Button>
                </Container>
            /*</Col>*/
        

        ); 

    }

  
}