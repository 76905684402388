import {Container, Row, Col} from 'react-bootstrap'; 


export default function LoadScreen() {

    return (
        <Container>
            <Row style={{marginTop: 20}} className="justify-content-center">
                    <Col style={{fontSize: 20}} align="center">
                        <p> Making Vedic astrology calculations, please wait ...</p>
                    </Col>
            </Row>
        </Container>
    ); 
}