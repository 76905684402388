export default function ChartTable({houses}) {

    houses = houses.map((elem) => getHouseData(elem)); 


    return (
        
        <table>
            <tr>
                <td className="chart">{houses[10]}</td>
                <td className="chart">{houses[0]}</td>
                <td className="chart">{houses[1]}</td>
                <td className="chart">{houses[2]}</td>
            </tr>
            <tr>
                <td className="chart">{houses[0]}</td>
                <td></td>
                <td></td>
                <td className="chart">{houses[3]}</td>  
            </tr>
            <tr>
                <td className="chart">{houses[9]}</td>
                <td></td>
                <td></td>
                <td className="chart">{houses[4]}</td>
            </tr>
            <tr>
                <td className="chart">{houses[8]}</td>
                <td className="chart">{houses[7]}</td>
                <td className="chart">{houses[6]}</td>
                <td className="chart">{houses[5]}</td>
            </tr>
        </table>
    );

    function getHouseData(arr) {

        if(arr[0] == '*') return arr; 

        let result = ""; 

        

        for(let i = 0; i < arr.length; i+=2) {
            result += arr[i]; 
            result += "\n"; 
        }

        return result; 

    }
    
    



}