import {Modal, Alert, Button} from 'react-bootstrap';  

export default function IncDataAlert({visible, showPopup, errorText}) {

    if(errorText == "") return; 


    return (
        <Modal
            show={visible}
            onHide={() => {/* Might put smt here maybe */ }}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title>Data Entered Incorrectly!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Alert key={'danger'} variant={'danger'}>
                {errorText}
            </Alert>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => showPopup(false)}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

    )
    

}