import './App.css';
import React from 'react';

import TitleScreen from './components/TitleScreen/TitleScreen';
import InfoForm from './components/InfoForm/InfoForm';
import TopNavBar from './components/TopNavBar/TopNavBar';
import SideNav from './components/SideNav/SideNav';
import TableScreen from './components/data-screens/table-screens/TableScreen';
import DataManager from './utility/DataManager';
import Chart from './components/data-screens/chart/Chart';
import LoadScreen from './components/data-screens/LoadingScreen';

import 'bootstrap/dist/css/bootstrap.min.css';
import PredictionScreen from './components/data-screens/prediction/PredictionScreen';
import {Image} from 'react-bootstrap'; 
import { Data } from '@react-google-maps/api';
import PlanetCodeBanner from "./PlanetCodeBanner.jpeg"

// TODO: Implement Info/Help and Author's Credit page 

// TODO: Store form data somewhere, take input for vedic houses or whatev. On the screenshots

// TODO: For SideNav, research into Router, Navbar, Switch combo for better navbar system

export default class App extends React.Component {

   state = {
    visible : 0,
    sideNav : 0, 
    sideNavVisible : false
  }

  //To hide the title screen 

  // 0 -> Title Screen, 1 -> Info Form, 2 -> Prints, 3-> Table

  
  setVisibility = (visibility) => {
    DataManager.dataLoaded = false; 
    this.setSideNavVis(visibility == 3); 
    this.sideNavToggle(0); 

     this.setState((state) => {
        return {visible: visibility}; 
     })
  }

  setSideNavVis = (sideNavVis) => {
    this.setState((state) => {
      return {sideNavVisible : sideNavVis}; 
    })
  }

  sideNavToggle = (sideNav) => {
    DataManager.dataLoaded = false; 
    this.setState((state) => {
       return {sideNav: sideNav}; 
    })
 }

  render() {

    // This is so annoying to use, but less annoying then figuring out how to use the h-25 stuff
    let screenHeight = window.screen.height * 0.87;

    let visible = this.state.visible; 

    
    console.log("Hello there"); 

    return (

      

      //Title screen container 
      <div id="App">

        <Image src={PlanetCodeBanner} style={{width:"70%"}}></Image>

        <TopNavBar setVisibility={this.setVisibility}></TopNavBar>

        <SideNav visibility={visible} sideNavToggle={this.sideNavToggle} 
          setVisibility={this.setSideNavVis} showing={this.state.sideNavVisible}></SideNav>

        <TitleScreen hidden={visible !== 0} setVisibility={this.setVisibility}></TitleScreen>

        { /* This is the form container */ }
        <InfoForm hidden={visible !== 1} setVisibility={this.setVisibility}></InfoForm>

        <TableScreen hidden={visible !== 3} screen={this.state.sideNav}></TableScreen>

        <Chart hidden={visible !== 4} screen={this.state.sideNav}></Chart>

        <PredictionScreen hidden={visible !== 5} screen={this.state.sideNav}></PredictionScreen>

      </div>
    );
  }
}
