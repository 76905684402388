import TableRow from "./TableRow";
import {Table, Container, Row, Col} from 'react-bootstrap'; 
import DataManager from "../../../../utility/DataManager";
import { Data } from "@react-google-maps/api";

export default function TabularData({tableData}) {

    let columnHeaders = tableData.columnHeaders; 

    let rowHeaders = tableData.rowHeaders; 



    if(rowHeaders != null && columnHeaders[0] == " ") columnHeaders.unshift(" "); 

    columnHeaders = columnHeaders.map((header) => {return <th>{header}</th>}); 

    /*
    rowHeaders.unshift(" "); 
    columnHeaders.unshift(" "); 

    */

    let i = 0; 

    let rows = tableData.rows.map((arr) => {

        let rowH = null; 
        if(rowHeaders != null) rowH = rowHeaders[i]; 
        i++; 
        return <TableRow array={arr} rowH={rowH}></TableRow>
    }); 

    DataManager.tableLoaded = true; 

    return (

        <Container fluid>

        <Row className="justify-content-center">
            <Col xs lg = {6} align="center">
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                        {columnHeaders}
                        </tr>
                        {rows}  
                    </tbody>  
                </Table>

            </Col>

        </Row>
        </Container>

    ); 

}