import {useState} from 'react'; 
import DataManager from '../../../utility/DataManager';
import BackEndConnector from '../../../utility/BackEndConnector';
import {Container, Row, Col} from 'react-bootstrap'; 
import LoadScreen from '../LoadingScreen';

export default function PredictionScreen({hidden, screen}) {


    let [data, setData] = useState(null); 
    
    if(screen > 4) return; 

    if(hidden) return; 

    screen = (DataManager.sideNavLabels["5"][screen]).toLocaleLowerCase(); 

    console.log(DataManager.dataLoaded); 

    if(!DataManager.dataLoaded) {
        BackEndConnector.getData("prediction/" + screen).then((res) => setData(res)); 
    } 

    if(data == null ) {

        return <LoadScreen/>    
    }
    
    return (
        <Container >
            <Row style={{paddingLeft:100, paddingRight:20, paddingTop: 20}} 
                dangerouslySetInnerHTML={{__html: data}}>
            </Row>
        </Container> 
    );
}