import {Container, Row, Col} from 'react-bootstrap'; 
import BackEndConnector from '../../../utility/BackEndConnector';
import DataManager from '../../../utility/DataManager';
import "./Chart.css"; 
import ChartTable from './ChartTable';
import LoadScreen from '../LoadingScreen';

import {useState} from 'react'; 
import { LoadScriptNext } from '@react-google-maps/api';
import { AiOutlineLoading } from 'react-icons/ai';

export default function Chart({hidden, screen}) {

    let [data, setData] = useState(null); 
    let [baseChart, setBaseChart] = useState(null); 

    if(screen > 7) return; 

    if(hidden) return; 

    if(!DataManager.dataLoaded) {
        BackEndConnector.getData("chart/" + screen).then((res) => setData(res)); 
        BackEndConnector.getData("chart/0").then((res) => setBaseChart(res)); 
    } 

    if(data == null || baseChart == null) {
        return <LoadScreen></LoadScreen>; 
        
    }
    else DataManager.dataLoaded = true; 

    let name = data.chartName; 
    let houses = data.houses; 
    let chartText = data.chartText; 

    let baseName = baseChart.chartName; 
    let baseHouses = baseChart.houses; 

    return (


        
        <Container hidden={hidden} style={{width:window.screen.width, height:window.screen.height * 0.87}}>

            <Row style={{marginTop:50}}>
                <Col align="center">
                    <ChartTable houses={houses}></ChartTable>
                </Col>

                <Col align="center">
                    <ChartTable houses={baseHouses}></ChartTable>
                </Col>
            </Row>
            <Row style={{marginTop:10}}>
                <Col align="center">
                    <p><b>
                        {name}
                        </b></p>
                </Col>

                <Col align="center">
                    <p><b>
                        {baseName}
                        </b></p>
                </Col>
            </Row>
            <Row style={{marginTop:40}}>
                <Col style={{fontSize:15, lineHeight:2}} dangerouslySetInnerHTML={{__html:chartText}}></Col>
            </Row>

        </Container>
    );
}