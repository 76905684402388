import { GoogleMap, useLoadScript, MarkerF} from '@react-google-maps/api';

import {useState} from 'react'; 

import './MapForm.css'; 

export default function MapForm({setFormLocation}) {

    const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyBfze98K_dTcAVK_mbukQgexRfmtQ4wRNE"}); 
    const [clickedLocation, setClickedLocation] = useState(null);

    let defaultCenter = {lat:21, lng: 79}; 
    if(clickedLocation !== null) defaultCenter= clickedLocation; 
    
    if(isLoaded) {
        return (
            <GoogleMap zoom={4} center={defaultCenter} mapContainerClassName='map-style' onClick={mapClicked} 
            options={{streetViewControl: false}}>

                <MarkerF hidden={clickedLocation === null} position={clickedLocation}></MarkerF>
                
            
            </GoogleMap>
        ); 
    }
    else return ( <p>Loading...</p>)

    function mapClicked(event) {

        let position = event.latLng; 

        let coords = {lat: position.lat(), lng: position.lng()}

        // Will need to convert to minutes when doing axios 
        setFormLocation([coords.lat, coords.lng]); 

        setClickedLocation(coords); 
    }
}

