import {Container, Row, Col, Button} from 'react-bootstrap'; 

import SideNav from '../SideNav/SideNav';


export default function TitleScreen({hidden, setVisibility}) {

    // This is so annoying to use, but less annoying then figuring out how to use the h-25 stuff
    let screenHeight = window.screen.height * 0.8;
    let screenWidth = window.screen.width; 

    return (

        <Container hidden={hidden} className="align-items-center" fluid >

            <Row style={{padding:"0", marginTop: 40}} className="justify-content-md-center">
                <Col xs lg="3" align="center">
                <h1 style={{color: "#26AEE9", fontSize: 40}}><b>Planet Code</b></h1>
                <h4 style={{color: "#0F5E8E"}}>Vedic Astrology Tool</h4>
                </Col>
            </Row>

            <Row className="justify-content-md-center">
                
                <Col xs lg="2" align="center" style={{padding:"0", marginTop: 40}}>

                    <Button bsStyle="primary" size="lg"
                    onClick={() => {
                        setVisibility(1); 
                    }}> 
                        Start
                    </Button>
                </Col>
            </Row>

        </Container>

    );
}