import BackEndConnector from '../../../utility/BackEndConnector';
import DataManager from '../../../utility/DataManager';
import LoadScreen from '../LoadingScreen';

import TabularData from './TableConfig/TabularData';

import {useState} from 'react'; 

export default function Horoscope() {

    let [data, setData] = useState(null); 

    if(!DataManager.dataLoaded) BackEndConnector.getData("table/HOROSCOPE").then((res) => setData(res)); 

    if(data == null) {
        
        return (<LoadScreen />); 
        
    }
    else DataManager.dataLoaded = true; 
   
    return (
        <>
        <TabularData tableData={data}></TabularData> 
        </>

    ); 
}