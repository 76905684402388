import {useState} from 'react'; 

import {Modal, Alert, Button} from 'react-bootstrap';  

export default function NoDataAlert({visible, showPopup}) {


    return (
        <Modal
            show={visible}
            onHide={() => {/* Might put smt here maybe */ }}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
            <Modal.Title>Data not loaded</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Alert key={'info'} variant={'info'}>
                Data not loaded. First fill out Info Form before accessing this.
                If you have already filled out the form, the data may still be loading. 
            </Alert>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => showPopup(false)}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

    )
    

}