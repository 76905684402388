import DataManager from "../../../../utility/DataManager";

export default function TableRow({array, rowH}) {

    if(rowH != null && array.rowValues[0] !== rowH) array.rowValues.unshift(rowH); 

    let row = array.rowValues.map((obj) => {return <td> {obj} </td>}); 

    return (
        <tr>
            {row}
        </tr>
    ); 


}