import axios from 'axios'; 
import DataManager from './DataManager';

export default class BackEndConnector {

    static user = {
        date: null, 
        lat: null, 
        latMin: null, 
        lng: null, 
        lngMin : null,
        time: null, 
        gender: null
    }
    static async setUserData(date, lat, latMin, lng, lngMin, time, gender) {

        this.user = {date: date, lat : lat, latMin: latMin, lng : lng, lngMin : lngMin, 
            time : time, gender: gender}; 
    }

    static async getData(link) {

        if(this.user.date == null) return new Promise(() => {return null});

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: ("https://0feplwen8h.execute-api.us-east-1.amazonaws.com/Prod/" + link),

        
            headers: { 
            'Content-Type': 'application/json'
            },
            data : JSON.stringify(this.user)
        };

        return await axios(config).then((res) => {
            
            
            if(res == null) return null; 
            if(res != null) DataManager.dataLoaded = true; 

            console.log(res.data["table/horoscope"]); 
            
            return JSON.parse(res.data[link.toLowerCase()]); 
        })
        .catch((err) => console.log(err.response));  
    }              
    // Add the database stuff
}
