import Ashtavarga from "./Ashtavarga";
import Horoscope from "./Horoscope";
import Planets from "./Planets";
import Relation from "./Relation";
import Saptavarga from "./Saptavarga"; 
import DasaDetails from "./DasaDetails";
import Health from "./Health";
import Aspects from "./Aspects";
import Staanabala from "./Staanabala";
import Kaalabala from "./Kaalabala";
import Shudbala from "./Shudbala";
import Analysis from "./Analysis"; 
import Kalachakra from "./Kalachakra";
import Profession from "./Profession";
import Family from "./Family";
import Transit from "./Transit";
import Marriage from "./Marriage";


export default function TableScreen({screen, hidden}) {

    if(hidden) return null; 

    switch(screen) {
        case 0: return <Horoscope/>
        case 1: return <Planets />
        case 2: return <Relation />
        case 3: return <Ashtavarga />
        case 4: return <Saptavarga />
        case 5: return <DasaDetails />
        case 6: return <Aspects />
        case 7: return <Staanabala />
        case 8: return <Kaalabala />
        case 9: return <Shudbala />
        case 10: return <Analysis />
        case 11: return <Health />
        case 12: return <Kalachakra />
        case 13: return <Profession />
        case 14: return <Family />
        case 15: return <Transit />
        case 16: return <Marriage />
    }
} 