import {Nav, Navbar, Container, Modal, Alert, Button} from 'react-bootstrap';
import DataManager from './../../utility/DataManager'; 
import {useState} from 'react'; 
import NoDataAlert from '../NoDataAlert/NoDataAlert';
import { useRef } from 'react';


export default function TopNavBar({setVisibility}) {

    let [popupVisibility, showPopup] = useState(false); 

    return (
        <>
        <Navbar collapseOnSelect expand="sm" data-bs-theme="dark" style={{background: "#0F5E8E", border: "1px solid #0F5E8E"}}>
            <Container>
                <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                <Navbar.Collapse id="responsive-navbar-nav">
                     <Nav variant="tabs" className="me-auto">
                        <Nav.Link href="#home" style={{paddingRight: 40}} onClick={() => switchTab("TitleScreen")}>Planet Code</Nav.Link>
                        <Nav.Link onClick={() => switchTab("InfoForm")} href="#prints" style={{paddingRight: 20, color: "#FFFFFF"}}>Data-Entry</Nav.Link>
                        <Nav.Link onClick={() => switchTab("TableScreen")} href="#table" style={{paddingRight: 20, color: "#FFFFFF" }}>Table</Nav.Link>
                        <Nav.Link onClick={() => switchTab("Chart")} href="#chart" style={{paddingRight: 20, color: "#FFFFFF" }}>Chart</Nav.Link>
                        <Nav.Link onClick={() => switchTab("PredictionScreen")} href="#prediction" style={{paddingRight: 20, color: "#FFFFFF"}}>Prediction</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        { /* Code for the popup if data not loaded, should probably put in separate component */ }
        <NoDataAlert visible={popupVisibility} showPopup={showPopup}></NoDataAlert>
        </>
    ); 

    function switchTab(newTab) {

        if(!DataManager.userEntered && newTab !== "TitleScreen") {
            showPopup(true); 
        } 
        else {
            setVisibility(DataManager.navbarConvert[newTab]); 
        }   
    }
}