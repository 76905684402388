export default class DataManager {
    // Access DatabaseConnector class access here 
    static userEntered = false; 

    static dataLoaded = false;

    static navbarConvert = {
        "TitleScreen" : 0, 
        "InfoForm" : 1, 
        "TableScreen" : 3, 
        "Chart" : 4, 
        "PredictionScreen" : 5
    }
    
    static sideNavLabels = {
        "0": [],
        "1": [], 
        "3": ["Horoscope", "Planets", "Relation", "Ashtavarga", "Saptavarga", 
        "DASA Details", "Aspects", "Staanabala", "Kaalabala", "Shudbala", 
        "Analysis", "Health", "Kalachaka", "Profession", "Love,Travel,Edu",
         "Transit", "Marriage"],
        "4": ["Rasi", "Drekkana", "Sapthams", "Navamsa", "Dasamsa",
         "Dwadasam", "Transits", "Bhavachart"],
         "5": ["Birth", "Medical", "Planet", "Transit", "Yoga"]
    }
} 